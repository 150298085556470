import ReactTimeAgo from 'react-time-ago';
import { MessageType } from '../../../../types/MessageType';
import ActionState from '../../../OrderLifeCycleState/State/ActionState';
import { useMemo } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import { CheckOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { getLanguage } from 'helpers/getLanguage';
import { getInitials } from 'helpers/getStringInitials';
import { useTranslation } from 'react-i18next';

type MessageItemProps = {
	isMerchant: boolean;
	message: MessageType;
	orderNumber: string;
	userName: string;
};

function convertURL(inputString: string) {
	// Regular expression to match URLs
	const urlPattern = /(https?:\/\/[^\s]+)/g;

	// Split the input string based on the URLs
	const parts = inputString.split(urlPattern);

	// Map through the parts and replace URLs with anchor tags
	return parts.map((part, index) => {
		// Check if the part matches a URL
		if (urlPattern.test(part)) {
			return (
				<a key={index} href={part} target='_blank' rel='noopener noreferrer'>
					{part}
				</a>
			);
		}

		// Return plain text if not a URL
		return part;
	});
}
function MessageItem({ isMerchant, message, orderNumber, userName }: MessageItemProps) {
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const downloadFile = (event: any) => {
		axios
			.get(API_ENDPOINTS.getFileUrl.replace('%orderNumber%', orderNumber).replace('%fileName%', message.fileId), {
				responseType: 'arraybuffer',
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				const a = document.createElement('a');
				a.href = `data:image/jpeg;base64,${base64}`;
				a.download = event.target.innerText;
				a.click();
			})
			.catch((err) => console.error(err));
	};

	const messageBy = useMemo(() => {
		if (message.role === 'ADMIN' || message.role === 'SUPER_USER') {
			return 'admin';
		} else if ((message.role === 'MERCHANT' && isMerchant) || (message.role === 'CLIENT' && !isMerchant)) {
			return 'mine';
		} else if ((message.role === 'CLIENT' && isMerchant) || (message.role === 'MERCHANT' && !isMerchant)) {
			return 'theirs';
		}
	}, [isMerchant, message]);

	if (message.type === 'ACTION') {
		return (
			<div className={`chat-message-container`}>
				<ActionState actionState={message.clientAction || message.merchantAction || message.adminAction} size='small' />
				<span className={`time`}>
					{message.timestamp ? (
						<ReactTimeAgo date={message.timestamp} locale={getLanguage(getUserLanguageInCookie())} />
					) : (
						<></>
					)}
					{message.read ? (
						<>
							<CheckOutlined style={{ color: 'green' }} />
							<CheckOutlined style={{ color: 'green' }} />
						</>
					) : (
						<>
							<CheckOutlined />
							<CheckOutlined />
						</>
					)}
				</span>
			</div>
		);
	} else if (message.type === 'TEXT') {
		return (
			<div className={`chat-message-container relative ${messageBy}`}>
				{messageBy === 'admin' && <div className='mx-9 my-1 text-xs'>{`${t('agent')} ${message.fullName}`}</div>}
				{messageBy === 'theirs' && <div className='mx-9 my-1 text-xs'>{message.fullName}</div>}
				{messageBy === 'mine' && <div className='text-xs my-1 flex justify-end'>{message.fullName}</div>}
				<div
					className={`chat-message-container relative ${messageBy}`}
					style={{ ...(messageBy === 'theirs' ? { minWidth: 'auto', width: 'auto', maxWidth: '100%' } : {}) }}
				>
					{messageBy === 'theirs' && (
						<div className='w-[30px] h-[30px] rounded-full border-[1px] border-solid border-black flex items-center justify-center absolute top-[-5px] '>
							{getInitials(message.fullName)}
						</div>
					)}
					<p style={messageBy === 'theirs' ? { marginInlineStart: '35px' } : {}}>{convertURL(message.content)}</p>
					<span className={`time time-text`}>
						{message.timestamp ? (
							<ReactTimeAgo date={message.timestamp} locale={getLanguage(getUserLanguageInCookie())} />
						) : (
							<></>
						)}
						{message.read ? (
							<>
								<CheckOutlined style={{ color: 'green' }} />
								<CheckOutlined style={{ color: 'green' }} />
							</>
						) : (
							<>
								<CheckOutlined />
								<CheckOutlined />
							</>
						)}
					</span>
				</div>
			</div>
		);
	} else if (message.type === 'FILE') {
		return (
			<div className={`chat-message-container ${messageBy}`}>
				<button onClick={downloadFile}>{message.fileLabel}</button>
				<span className={`time`}>
					{message.timestamp ? (
						<ReactTimeAgo date={message.timestamp} locale={getLanguage(getUserLanguageInCookie())} />
					) : (
						<></>
					)}
					{message.read ? (
						<>
							<CheckOutlined style={{ color: 'green' }} />
							<CheckOutlined style={{ color: 'green' }} />
						</>
					) : (
						<>
							<CheckOutlined />
							<CheckOutlined />
						</>
					)}
				</span>
			</div>
		);
	}

	return <></>;
}

export default MessageItem;
