import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { CompanyName, tablLogo } from '../assets/constant/fallback-config';
import inzo from '../assets/logos/inzo.png';
import webbats from '../assets/logos/webbats.png';
import { useConfigurationContext } from 'store/ConfigurationContext';
import { Grid } from 'antd';

function MainWrapper({ children }: { children: React.ReactNode }) {
	const title = process.env.REACT_APP_TITLE || 'INZO P2P';
	const logo = process.env.REACT_APP_ICON || true;

	const getLogo = () => {
		return logo === 'webbats' ? webbats : inzo;
	};
	return (
		<>
			<Helmet>
				<title>{title}</title>
				<link rel='icon' type='image/x-icon' href={getLogo()} />
			</Helmet>
			{children}
		</>
	);
}

export default MainWrapper;
