import { LanguagePhrases } from '../models/LanguagePhrases';

export const frenchPhrases: LanguagePhrases = {
	yesterday: 'Hier',
	today: 'Aujourd’hui',
	daysAgo: 'Il y a',
	home: 'maison',
	signinTitle: 'Connectez-vous à votre compte de paiement',
	signinDesc: "Connectez-vous à l'aide des identifiants du portail client",
	email: 'Email',
	password: 'Mot de passe',
	forgotPassword: 'Mot de passe oublié?',
	signIn: 'Se connecter',
	emailRequired: 'Le champ email ne doit pas être vide!',
	passwordRequired: 'Le champ mot de passe ne doit pas être vide!',
	somethingWrong: "Quelque chose s'est mal passé!",
	emailMsg: 'Veuillez vous assurer que votre adresse email est valide!',
	passwordMsg:
		"Assurez-vous que votre mot de passe comporte au moins 8 caractères, et qu'il inclut au moins une lettre majuscule, une lettre minuscule et un caractère spécial.",
	becomeMerchant: 'devenir marchand',
	yourAccountIsInactive: "Votre compte est inactif. Veuillez contacter votre administrateur pour l'activer",
	regularUserHome: "Accueil de l'utilisateur régulier",
	merchantHome: 'Accueil marchand',
	merchantsOffer: 'Offres des commerçants',
	notificationsHeading: 'Notifications',
	notificationsSubtitle: "Vous donner les moyens d'alertes opportunes ! Restez connecté, restez informé.",
	searchInMerchantsTable: 'Rechercher dans le tableau des marchands',
	problemLoadingListOfMerchants: 'Il y a un problème lors du chargement de la liste des marchands',
	name: 'nom',
	rating: 'notation',
	totalReview: 'Révision totale',
	ordersCompleted: 'Commandes terminées',
	price: 'prix',
	available: 'dostępny',
	minLimit: 'limite minimale',
	maxLimit: 'limite maximale',
	buy: 'acheter',
	sell: 'vendre',
	accountVerification: 'Vérification de compte',
	problemLoadingMerchant: 'Un problème est survenu lors du chargement des informations sur le marchand',
	merchantInfo: 'informations sur le marchand',
	wantToSell: 'je veux vendre',
	wantToBuy: 'je veux acheter',
	amountIsRequired: 'le montant est requis',
	willReceive: 'Je vais recevoir',
	willSend: "j'enverrai",
	paymentMethod: 'mode de paiement',
	paymentMethodIsRequired: 'Le mode de paiement est requis',
	valueInRangeError: 'le montant doit être dans cette fourchette :',
	merchants: 'Marchands',
	countryCurrency: 'Pays/Devise',
	paymentMethods: 'méthodes de payement',
	myDetails: 'Mes Details',
	changePassword: 'Changer le mot de passe',
	myDetailsForm: 'Formulaire Mes informations',
	myProfile: 'Mon profil',
	firstName: 'Prénom',
	surname: 'Nom de famille',
	phone: 'Téléphone',
	address: 'Adresse',
	city: 'Ville',
	zip: 'Zipper',
	state: 'État',
	country: 'Pays',
	registrationDate: "Date d'inscription",
	newPassword: 'Nouveau mot de passe',
	currentPassword: 'Mot de passe actuel',
	confirmPassword: 'Confirmez le mot de passe',
	updatePassword: 'Mettre à jour le mot de passe',
	changePasswordForm: 'Formulaire de changement de mot de passe',
	yourPasswordGotUpdated: 'Votre mot de passe a été mis à jour',
	somethingWentWrongWithUpdatingPassword: "Une erreur s'est produite lors de la mise à jour du mot de passe",
	pleaseMakeSurePasswordIsValid:
		"Veuillez vous assurer que votre mot de passe comprend au moins une lettre majuscule, une lettre minuscule, un caractère spécial et que la longueur du mot de passe doit être d'au moins 8",
	twoPasswordsDoNotMatch: 'Les deux mots de passe doivent être identiques',
	myOffers: 'Mes offres',
	yourSellerFormGotUpdated: 'Votre formulaire vendeur a été mis à jour',
	somethingWentWrongWithUpdatingSellerForm: "Une erreur s'est produite lors de la mise à jour du formulaire du vendeur",
	yourBuyerFormGotUpdated: "Votre formulaire d'acheteur a été mis à jour",
	somethingWentWrongWithUpdatingBuyerForm:
		"Une erreur s'est produite lors de la mise à jour du formulaire de l'acheteur",
	sellOffer: 'Offre de vente',
	profitRatio: 'Taux de profit',
	profitRatioIsRequired: 'Le taux de profit est requis',
	profitRatioInRangeError: 'Le taux de profit doit être entre',
	atleastSelectOneMethod: 'Veuillez sélectionner au moins un mode de paiement',
	maximumAmount: 'Quantité maximale',
	valueMustBePositive: 'La valeur doit être positive',
	mustBeGreaterThanMinValue: 'La valeur doit être supérieure à la valeur minimale',
	minimumAmount: 'Montant minimal',
	mustBeLessThanMaxValue: 'La valeur doit être inférieure à la valeur maximale',
	visibleForUsers: 'Visible pour les utilisateurs',
	no: 'Non',
	yes: 'Oui',
	update: 'Mise à jour',
	buyOffer: "Offre d'achat",
	logout: 'Se déconnecter',
	companies: 'Entreprises',
	news: 'Nouvelles',
	privacyPolicy: 'politique de confidentialité',
	termsOfUse: "Conditions d'utilisation",
	merchantsOfferSubtitle:
		"Découvrez les échanges P2P Tether USDt sans frais et choisissez votre méthode de paiement préférée sur notre plateforme dès aujourd'hui !",
	limit: 'Limite',
	countries: 'Des pays',
	min: 'Le minimum',
	max: 'Maximum',
	all: 'Toute',
	merchantProfile: 'Profil marchand',
	availableLimit: 'Disponible / Limite',
	trade: 'Commerce',
	fees: 'Frais',
	reviews: 'Commentaires',
	buyers: 'Acheteurs',
	sellers: 'Les vendeurs',
	termsAndConditions: 'termes et conditions',
	unitPrice: 'Prix ​​unitaire',
	youSale: 'Vous vendez',
	youReceive: 'Vous recevez',
	selectPaymentMethods: 'Sélectionnez les méthodes de paiement',
	account: 'Comptes',
	note: 'Note',
	pleaseWriteYourNote: "S'il vous plaît écrivez votre note",
	certainPaymentMethodsMayHaveFees:
		'Certaines méthodes de paiement peuvent avoir des frais et des limites quotidiennes fixées par le fournisseur de paiement.',
	youPay: 'Tu payes',
	orders: 'Ordres',
	welcomeBack: 'Content de te revoir',
	loginToYourAccount: 'Connectez-vous à votre compte',
	pleaseEnterYourEmail: 'Veuillez saisir votre e-mail',
	enterPassword: 'Entrer le mot de passe',
	notRegistered: 'Non enregistré?',
	or: 'ou',
	createAnAccount: 'Créer un compte',
	readOur: 'Lisez notre',
	signinWarningNote:
		"AVERTISSEMENT : L'accès non autorisé à ce système est interdit et sera poursuivi par la loi. En accédant à ce système, vous acceptez que vos actions soient surveillées si une utilisation non autorisée est suspectée",
	allRightsReserved: 'Tous les droits sont réservés',
	availableIsRequired: 'Disponible est requis',
	somethingWentWrongVerification:
		"Quelque chose s'est mal passé, veuillez vous assurer que les documents que vous avez téléchargés sont vérifiés",
	myOrders: 'Mes commandes',
	myOrderSubtitle: 'L histoire se répète : des réorganisations sans effort, à chaque fois !',
	calculatedPrice: 'Prix ​​final',
	status: 'Statut',
	dateRange: 'Période',
	sent: 'Envoyé',
	recieved: 'Reçu',
	active: 'Actif',
	pending: 'En attente',
	completed: 'Terminé',
	cancel: 'Annuler',
	inDispute: 'En litige',
	id: 'ID',
	quantity: 'Quantité',
	amount: 'Montant',
	dateAndTime: 'Date et heure',
	searchOrder: 'Ordre de recherche',
	transferPaymentToSeller: 'Transférer le paiement au vendeur',
	pendingSellerToReleasePayment: 'En attente du vendeur pour libérer le paiement',
	orderNumber: 'Numéro de commande',
	timeCreated: 'Heure de création',
	activeOrder: 'Commande active',
	pendingOrder: 'Commande en cours',
	completedOrder: 'Commande terminée',
	cancelledOrder: 'Commande annulée',
	buyFrom: 'Acheter à',
	buyUSDFrom: 'ACHETER USD à partir de',
	buyIQDFrom: 'ACHETER IQD de',
	orderCreatedConfirmation: 'La commande est créée. Veuillez attendre la confirmation du système',
	orderWaitingMessage:
		'En attente de la confirmation de paiement du vendeur pour valider la commande, 95 % de la commande de ce vendeur a été exécutée dans les 3 minutes.',
	orderCompletedMessage:
		"La commande a été marquée avec succès comme terminée. Si vous rencontrez un problème de paiement, contactez l'assistance pour résoudre le problème.",
	youSuccessfullyBought: 'Vous avez acheté avec succès',
	IQDFrom: 'IQD de',
	thisOrderHasBeenCancelled: 'Cette commande a été annulée',
	thisOrderIsInDispute: 'Cette commande est en litige',
	orderAmount: 'Montant de la commande',
	youPaid: 'Tu as payé',
	accountName: 'Nom du compte',
	orderDetails: 'détails de la commande',
	payWithZenCash: 'Payer avec ZenCash',
	selectPaymentCompleted: 'Sélectionnez Paiement terminé pour notifier le vendeur',
	paymentCompletedNotify: 'Paiement effectué, informer le vendeur',
	paymentReceived: 'Paiement reçu',
	BackToHome: 'De retour à la maison',
	reorder: 'réorganiser',
	contactSupport: 'Contactez le support',
	cancelOrder: 'annuler la commande',
	completeYourPayment: 'Terminer votre paiement ?',
	note1: "Veuillez confirmer que vous avez réussi à transférer de l'argent au vendeur.",
	note2:
		"Remarque : Ne continuez pas si vous n'avez pas effectué le paiement. Cela pourrait entraîner la suspension du montant.",
	rateTheMerchant: 'Évaluez le marchand :',
	orderS: 'ordres',
	lastSeen: 'Vu pour la dernière fois',
	ago: 'il y a',
	yourName: 'Votre nom',
	yourEmail: 'Votre e-mail',
	yourPhone: 'Ton téléphone',
	subject: 'Sujet',
	message: 'Message',
	nameIsRequired: 'Le nom est requis',
	emailIsRequired: "L'e-mail est requis",
	phoneIsRequired: 'Le téléphone est requis',
	subjectIsRequired: 'Le sujet est requis',
	messageIsRequired: 'Un message est requis',
	sendMessage: 'Envoyer le message',
	bestSellers: 'Meilleures ventes',
	maxOrders: 'Commandes maximales',
	minOrders: 'Commandes minimales',
	importantNote: 'Note importante',
	confirm: 'Confirmer',
	confirmContactingMerchantInChat:
		"Veuillez vous assurer de contacter le marchand avant d'effectuer un paiement. {{company_name}} ne sera pas responsable de tout type de perte.",
	myAccounts: 'Mes Comptes',
	myAccountsSubtitle:
		"Obtenez une vue d'ensemble claire de vos avoirs Tether USDt P2P, y compris les soldes et les détails du compte.",
	tradingPlatform: 'Plateforme de Trading',
	accountType: 'Type de Compte',
	accountGroup: 'Groupe de Compte',
	accountNumber: 'Numéro de Compte',
	balance: 'Solde',
	onHoldBalance: 'Solde en Attente',
	client: 'Client',
	emailUs: 'Nous Envoyer un Email',
	merchant: 'Marchand',
	writeMessage: 'Écrire un Message',
	availability: 'Disponibilité',
	buyersTerm: "Conditions et modalités de l'acheteur",
	sellersTerm: 'Conditions et modalités du vendeur',
	bankDeposit: 'Dépôt bancaire uniquement',
	accHolder: "Titulaire du compte d'origine",
	thirdParty: 'Paiement par tiers non accepté',
	wedFri: 'Mer-Ven',
	merchantReview: 'Avis du commerçant',
	apiNotFound: 'API introuvable',
	unableToAuthenticateTheUser: 'Session invalide ou expirée',
	somethingWentWrong: `Quelque chose s'est mal passé`,
	constraintViolated: 'Contrainte violée',
	recordIsLinked: `L'enregistrement est lié`,
	parametersCannotBeNull: 'Les paramètres ne peuvent pas être nuls',
	methodArgumentsAreInvalid: 'Les arguments de la méthode ne sont pas valides',
	fileUploadingError: 'Erreur de téléchargement de fichier',
	parameterIsNotValid: `Le paramètre de requête n'est pas valide`,
	requiredParametersAreMissing: 'Les paramètres requis sont manquants',
	pleaseEnterValidEmailAddress: `S'il vous plaît, mettez une adresse email valide`,
	passwordDoesNotMatchRequirements:
		'Le mot de passe ne correspond pas aux exigences : minimum 8 et maximum 16 caractères, dont au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
	invalidMerchantOfferRequest: `Demande d'offre marchande invalide`,
	invalidTradingAccountGroup: 'Groupe de comptes de trading invalide',
	countryIsNotAllowed: `Le pays n'est pas autorisé dans le système P2P`,
	orderNumberProvidedIsInvalid: `Le numéro de commande fourni n'est pas valide`,
	orderIsClosed: `La commande est clôturée`,
	actionNotApplicable: 'Action non applicable',
	errorOccurredWhileProcessingYourRequest: `Une erreur s'est produite lors du traitement de votre demande`,
	problemOccurredWhileLoadingRecords: 'Un problème est survenu lors du chargement des enregistrements',
	problemOccurredWhileLoadingRecord: `Un problème est survenu lors du chargement de l'enregistrement`,
	problemOccurredWhileAddingRecord: `Un problème est survenu lors de l'ajout d'un enregistrement`,
	problemOccurredWhileUpdatingRecord: `Un problème est survenu lors de la mise à jour de l'enregistrement`,
	problemOccurredWhileDeletingRecord: `Un problème est survenu lors de la suppression de l'enregistrement`,
	recordAlreadyExists: `L'enregistrement existe déjà`,
	noRecordFound: 'Aucun Enregistrement Trouvé',
	accessDeniedMissingKyc: 'Accès refusé en raison de la disparition de Kyc',
	invalidCredentials: `les informations d'identification invalides`,
	invalidPaymentMethod: 'Mode de paiement invalide',
	invalidOfferType: `Type d'offre invalide`,
	orderIsInProgress: 'La commande est en cours',
	orderReviewIsAlreadyPresent: 'La revue de commande est déjà présente',
	orderIsClosedMoreThanWeekAgo: `La commande est clôturée il y a plus d'une semaine`,
	userIsInactive: `L'utilisateur est inactif`,
	userCountryNotAllowed: 'Pays utilisateur non autorisé',
	orderIsExpiredOrClosed: 'La commande est expirée ou fermée',
	invalidOrderAction: 'Action de commande invalide',
	onlyOneOrderAllowedAtTime: 'Une seule commande autorisée à la fois',
	invalidTradingAccount: 'Compte de trading invalide',
	merchantBelongsToDifferentCountry: `Impossible d'échanger! Le marchand appartient à un autre pays`,
	orderAmountIsMoreThanAvailableBalance: 'Le montant de la commande est supérieur au solde disponible!',
	merchantOfferNotAvailable: 'Offre marchande non disponible!',
	amountDoesNotSatisfyAvailableLimit: 'Le montant ne satisfait pas à la limite disponible!',
	iraq: 'Irak',
	lebanon: 'Liban',
	saudi: 'Arabie Saoudite',
	egypt: 'Egypte',
	jordan: 'Jordan',
	requestWasSentSuccessfully: 'La demande a été envoyée avec succès',
	noReviewsForThisMerchant: "Pas d'avis pour ce marchand",
	problemLoadingListOfOrders: 'Un problème est survenu lors du chargement de la liste des commandes',
	new: 'Nouveau',
	cancelled: 'Annulé',
	transferred: 'Transféré',
	sellerConfirmed: 'Vendeur confirmé',
	cancellationRequest: "Demande d'annulation",
	timedOut: 'Fin du temps',
	appealed: 'Appel',
	rejected: 'Rejeté',
	closed: 'Fermé',
	myOffersSubtitle: 'Ce sont les offres qui seront présentées à vos clients sur Merchant.',
	merchantDetails: 'Détails du marchand',
	merchantDetailsDescription:
		'Découvrez des profils de commerçants détaillés et leurs avis, offrant des informations précieuses pour améliorer votre expérience de trading P2P Tether USDt.',
	onlyClientsCanStartOrders: 'Seuls les clients peuvent lancer des commandes',
	maxValueRange: 'La valeur maximale doit être comprise entre 1 et {{amount}}',
	noteMinLength: 'La note doit contenir plus de 20 caractères',
	noteMaxLength: 'La note doit contenir moins de 1000 caractères',
	cantOpenOrderNows: 'Vous ne pouvez pas démarrer une nouvelle commande car vous avez une commande en cours',
	amountLessThanMerchantAvailable: 'Le montant doit être dans la fourchette appropriée',
	amountLessThanYourBalanceLimit: 'Le montant doit être inférieur à votre solde',
	orderWith: 'Commandez avec',
	receivingPaymentFromBuyer: `Réception du paiement de l'acheteur`,
	releasePaymentToBuyer: `Libérer le paiement à l'acheteur`,
	opened: 'ouvert',
	appeal: 'appel',
	expired: 'expiré',
	placed: 'mis',
	unknown: 'inconnu',
	pleaseWaitUntilMerchantAcceptOrder: 'Veuillez attendre que le marchand accepte la commande',
	pleaseAcceptTheOrderBeforeItsExpired: 'Veuillez accepter la commande avant son expiration',
	thisOrderHasBeenCanceled: 'Cette commande a été annulée',
	thisOrderHasBeenExpired: 'Cette commande a expiré',
	thisOrderHasBeenRejected: 'Cette commande a été rejetée',
	thisOrderIsInAppeal: 'Cette ordonnance est en appel',
	theOrderStateIsUnknown: `L'état de la commande est inconnu`,
	writeReviewAboutTheMerchant: 'Écrivez un avis sur le commerçant',
	reviewIsRequired: 'Un examen est requis',
	paymentDetails: 'Détails de paiement',
	paymentCompleted: 'Paiement terminé',
	accept: 'Accepter',
	reject: 'Rejeter',
	finishedSuccessfully: 'Terminé avec succès',
	chatIsDisabled: 'Le chat est désactivé',
	areYouSureYouWantToAcceptOrder: 'Êtes-vous sûr de vouloir accepter cette commande?',
	cancelThisOrder: 'Annuler cette commande',
	inzoDoesnNotProvideAutomaticRefunds: `{{company_name}} ne propose pas de remboursement automatique. N'annulez pas votre commande si vous avez payé mais n'avez pas reçu le remboursement. Soumettez un litige si vous avez besoin d'aide.`,
	waitForReceivingConfirmation: `Veuillez attendre la confirmation de la réception de l'argent`,
	gotIt: `J'ai compris`,
	makeSureToContactMerchant: `Veuillez vous assurer de contacter le commerçant avant d'effectuer tout paiement. {{company_name}} ne sera pas responsable de tout type de perte.`,
	makeSureToContactClient: `Veuillez vous assurer de contacter le client avant d'effectuer tout paiement. {{company_name}} ne sera pas responsable de tout type de perte.`,
	importNote: 'Note importante',
	paymentCompletedNote1: `Veuillez confirmer que vous avez réussi à transférer l'argent au vendeur.`,
	paymentCompletedNote2: `Remarque: Ne continuez pas si vous n'avez pas effectué le paiement. Cela pourrait entraîner la suspension du compte.`,
	sucessfullyCompletedPaymentToSeller: `J'ai effectué avec succès le paiement au vendeur.`,
	paymentReceivedNote1: `Veuillez confirmer que vous avez bien reçu l'argent de l'acheteur.`,
	paymentReceivedNote2: `Remarque: Ne continuez pas si vous n'avez pas reçu de paiement. Cela pourrait entraîner la suspension du compte.`,
	conofirmYouReceivedPayment: 'Confirmez que vous avez reçu le paiement.',
	sucessfullyCompletedPaymentToBuyer: `J'ai effectué avec succès le paiement à l'acheteur.`,
	areYouSureYouWantToReject: 'Êtes-vous sûr de vouloir rejeter cette commande?',
	transferWasSentSuccessfully: `Le transfert a été envoyé avec succès à l'autre partie`,
	accepted: 'Accepté',
	finishedSuccessfuly: 'Terminé avec succès',
	typeMessageHere: 'Tapez un message ici',
	markAllAsRead: 'Tout marquer comme lu',
	chooseMerchantFromYourCountry: 'Choisissez un commerçant de votre pays',
	feedbackOnThisOrder: 'Retour sur cette commande',
	visit: 'Visite',
	seeOrderDetails: 'Voir les détails de la commande',
	pleaseWaitUntilThePaymentIsReleased: 'Veuillez attendre que le paiement soit effectué',
	waitUntilMerchantReleasesPayment: 'Attendez que le commerçant effectue le paiement',
	waitUntilClientTransfersPayment: 'Attendez que le client transfère le paiement',
	waitUntilMerchantConfirmsReceivingPayment: 'Attendez que le commerçant confirme avoir reçu le paiement',
	waitUntilClientConfirmsReceivingPayment: 'Attendez que le client confirme avoir reçu le paiement',
	paymentTransfered: 'Paiement transféré',
	inzoDoesNotProvideAutoRefund: `{{company_name}} ne propose pas de remboursement automatique. Veuillez vous connecter au compte destinataire pour vérifier l'état du paiement.`,
	iReceivedAmountFromBuyer: `J'ai reçu {{amount}} de l'acheteur.`,
	iCheckedPaymentFrom: `J'ai vérifié que le paiement provient d'{{userName}}`,
	iAgreeToReleasePaymentToBuyer: `J'accepte de remettre mon paiement à l'acheteur.`,
	transferWasSentSuccessfullyClient: 'Vous avez acheté avec succès {{amount}} à {{username}}',
	transferWasSentSuccessfullyMerchant: 'Vous avez vendu avec succès {{amount}} à {{username}}',
	reviewSubmitted: 'Avis soumis avec succès',
	notificationNewOrderMessage: `Nouvelle commande {{orderType}} #{{orderNumber}} pour un montant de {{amount}} a été ouverte.`,
	notificationOrderNewMessage: `Nouveau message dans la commande #{{orderNumber}} à {{createdAt}}`,
	notificationOrderExpiryWarning: `Votre commande #{{orderNumber}} est sur le point d'expirer à {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `La commande #{{orderNumber}} a expiré.`,
	notificationOrderStatusUpdate: `Changement de statut de la commande #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Votre offre {{offerType}} a été retirée en raison de {{reason}}`,
	notificationAccountBalanceLow: `Le solde du compte de votre commerçant est faible. Solde actuel : {{amount}}`,
	notificationSellAvailableLow: `La quantité d'offre de vente que vous avez spécifiée est sur le point de s'épuiser. Mettez à jour la quantité pour que l'offre reste valable. Solde actuel de vente disponible : {{sellAvailable}}`,
	seeMore: 'Voir plus',
	noNotificationsFound: 'Aucune notification',
	totalOrder: 'Ordre total',
	offerType: 'Type de offre',
	offerNotAvailable: "Cette offre n'est pas disponible pour le moment.",
	errorProccessingMT5: "Une erreur s'est produite lors du traitement de la demande MT5.",
	amountDosentSatisfyLimit: "Le montant ne satisfait pas la limite autorisée pour l'offre !",
	noteErrorMessage: 'La note ne doit pas dépasser 1000 caractères !',
	type: 'Type',
	resonsForCancellation: 'Reason for cancellation',
	resonsForCancellationNote:
		'To avoid loss, do not cancel the order after you have made the payment. {{company_name}} is not responsible for any kind of loss',
	unableToMakePayment: 'Impossible de procéder au paiement',
	dontKnowHowToPay: 'Ne sais pas comment payer',
	discussedWithSeller: 'Discuté avec le vendeur pour ne pas procéder',
	poorSellerAttitude: 'Mauvaise attitude du vendeur',
	cantContactSeller: 'Impossible de contacter le vendeur',
	suspectSellerFraud: 'Soupçonner une fraude du vendeur',
	others: 'Autres',
	userBlock: 'Bloqué',
	userSuspend: 'Suspendu',
	userUnblock: 'Debloquer',
	newReview: 'Nouveau commentaire',
	userUnsuspend: 'Désuspendu',
	accessDenied: 'Accès refusé',
	chatHasBeenClosed: `Le chat a été fermé`,
	emailAlreadyExist: `L'e-mail existe déjà`,
	phoneNumberDoesNotMatchTheRequirement: 'Le numéro de téléphone ne correspond pas aux exigences',
	newPasswordAndConfirmPasswordNotSame: 'Nouveau mot de passe et confirmation du mot de passe différent',
	userIsBlocked: `L'utilisateur est bloqué`,
	userIsSuspended: `L'utilisateur est suspendu`,
	invalidOTP: 'Mot de passe à usage unique invalide',
	merchantNotFound: 'Marchand introuvable',
	cancelAppeal: "Annuler l'Appel",
	revert: 'REVENIR',
	release: 'LIBÉRER',
	copiedSuccessfully: 'Copié avec succès',
	userSuspended: 'Utilisateur suspendu',
	merchantNewReview: 'Nouveau commentaire',
	withdrawal: 'Retrait',
	deposit: 'Dépôt',
	chatnow: 'Chat Now',
	expireIn: 'Expire In',
	releasePayment: 'Release Payment To Buyer',
	paywith: 'Pay with {{method}}',
	notFound: 'Order Not Found',
	invalidType: 'Extension de fichier non valide. Veuillez télécharger un fichier au format JPG, JPEG, PNG ou PDF.',
	fileExceedLimit: 'La taille du fichier dépasse la limite autorisée (5 Mo).',
	accountLimit: 'Limite du compte',
	maxValueAvailable: 'Le montant est supérieur au disponible',
	cannotEnableOffer: "Impossible d'activer l'offre en raison d'un solde insuffisant",
	show: 'Afficher',
	entries: 'Entrées',
	sortBy: 'trié par',
	startDate: 'Date de début',
	endDate: 'Date de fin',
	enterYourOtp: 'Entrez le code de vérification de votre e-mail',
	amountmustbelessThanavailabel: 'Le montant doit être inférieur au solde disponible de {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Le montant doit être supérieur au minimum',
	minAllowedValue: 'Le montant minimum doit être supérieur ou égal à {{amount}} {{currency}}',
	maxAllowedValue: 'Le montant maximum doit être supérieur ou égal à {{amount}} {{currency}}',
	priceLow: 'Prix bas-élevé',
	priceHight: 'Prix élevé-bas',
	didNotreceiveOtp: 'Nie otrzymałeś kodu?',
	resend: 'Renvoyer',
	withdrawalOffer: 'Offre de retrait',
	depositOffer: 'Offre de dépôt',
	NEW_ORDER: 'Nouvelle commande',
	ORDER_NEW_MESSAGE: 'Nouveau message dans la commande',
	ORDER_EXPIRY_WARNING: "Avertissement d'expiration de commande",
	ORDER_EXPIRED: 'Commande expirée',
	ORDER_STATUS_UPDATE: 'Mise à jour du statut de la commande',
	OFFER_DISABLED: 'Offre désactivée',
	ACCOUNT_BALANCE_LOW: 'Solde du compte faible',
	SELL_AVAILABLE_LOW: 'Disponibilité de vente faible',
	MERCHANT_NEW_REVIEW: 'Nouvelle évaluation du commerçant',
	USER_SUSPEND: 'Utilisateur suspendu',
	USER_UNSUSPEND: 'Utilisateur réactivé',
	USER_BLOCK: 'Utilisateur bloqué',
	USER_UNBLOCK: 'Utilisateur débloqué',
	unitPriceChange: 'Le prix unitaire a changé. Veuillez vérifier les nouveaux prix avant de passer la commande.',
	allowBalance: 'Veuillez déposer {{amount}} USD pour activer les offres.',
	priceChange: 'Changement de prix',
	YouReceive: 'Vous recevez',
	Youpay: 'Vous payez',
	Seeorderdetails: 'Voir les détails de la commande',
	INSUFFICIENT_BALANCE: 'Solde insuffisant',
	COOLDOWN: 'Période de refroidissement',
	BY_ADMIN: "Par l'administrateur",
	termsAndConditionError: "Le commerçant n'a pas accepté les termes et conditions",
	termsAndConditionsTitle: 'Conditions générales P2P du commerçant {{company_name}}',
	acceptTerms: 'J accepte les termes et conditions',
	aggree: "D'accord",
	RegistrationAndqualification: 'Enregistrement et qualification',
	RegistrationAndqualification1:
		"Les commerçants doivent s'inscrire en tant que commerçant P2P {{company_name}} et fournir les informations requises pour la qualification.",
	RegistrationAndqualification2:
		"Les commerçants doivent fournir des documents d'identité valides avec une preuve de résidence, et une réunion sera tenue avec le commerçant pour authentifier son compte.",
	RegistrationAndqualification3:
		"La qualification est soumise à l'approbation de l'entreprise, et les conditions peuvent être modifiées à tout moment.",
	TransactionsAndfees: 'Transactions et frais',
	TransactionsAndfees1:
		'Les commerçants doivent se conformer aux frais et taux approuvés pour le service P2P {{company_name}} Merchant.',
	TransactionsAndfees2:
		'La société a le droit de mettre à jour les frais et les prix et d`en informer les commerçants.',
	CommitmentTosafety: 'Engagement envers la sécurité',
	CommitmentTosafety1:
		'Les commerçants doivent prendre des mesures de sécurité strictes pour protéger les informations et transactions des clients.',
	CommitmentTosafety2: 'Un environnement sûr et sécurisé doit être fourni pour effectuer des transactions.',
	Compliancewithlawsandregulations: 'Conformité aux lois et réglementations',
	Compliancewithlawsandregulations1:
		'Les commerçants doivent se conformer à toutes les lois et réglementations liées aux services financiers et à P2P {{company_name}} Merchant.',
	Positiveinteractionwithcustomers: 'Interaction positive avec les clients',
	Positiveinteractionwithcustomers1:
		'Les commerçants doivent interagir positivement avec les clients et fournir un support efficace et une rapidité d exécution des commandes. Les plaintes et les problèmes doivent être résolus rapidement et efficacement.',
	Terminationofservice: 'Résiliation du service',
	Terminationofservice1:
		'La société se réserve le droit de résilier le service du commerçant si les termes et conditions convenus ne sont pas respectés.',
	send: 'Envoyer',
	amountDoesNotSatisfyAccountGroupLimit: 'Le montant ne satisfait pas la limite du groupe de comptes',
	limitReached: 'Limite de commandes du commerçant atteinte !',
	OTPIsrequired: 'OTP est requis !',
	Pleaseuploadthepaymentproof: 'Veuillez télécharger la preuve de paiement',
	ChooseFile: 'Choisir un fichier',
	selectPaymentProof: 'Sélectionner la preuve de paiement',
	fileUploadRequired: 'Le fichier est requis ',
	download: 'Télécharger',
	live: 'En direct',
	submit: 'Soumettre',
	back: 'Retour',
	of: 'de',
	items: 'articles',
	agent: 'Agent',
	tradersRoom: 'Salle des commerçants',
	exchangeRateUpdate: 'Le taux de change a été modifié de {{oldExchangeRate}} à {{newExchangeRate}} par {{role}}.',
	EXCHANGE_RATE_UPDATE: 'Mise à jour du taux de change',
	ORDER_REJECTION_WARNING: 'Avertissement de rejet de commande',
	sessionExpired: 'Session expirée',
	orderRejectionWarning: 'Rejeter la commande une fois de plus désactivera vos offres',
	suspendClient: 'Suspendre le client',
	suspendMerchant: 'Suspendre le commerçant',
	unsuspendMerchant: 'Rétablir le commerçant',
	unsuspendClient: 'Rétablir le client',
	restricted: 'restreint',
	completionRate: 'Réalisation',
	otpexpired: 'Le code OTP a expiré!',
	reachedotplimit: 'Vous avez atteint le nombre maximum de tentatives pour l OTP!!',
	annoncement: 'Maintenance planifiée du portail de {{from}} à {{to}}',
	validationChatMessage: 'les caractères spéciaux <, >, & ne sont pas autorisés.',
	showLiveChat: 'Afficher le chat en direct',
	hideLiveChat: 'Masquer le chat en direct',
};
