import { LanguagePhrases } from '../models/LanguagePhrases';

export const polishPhrases: LanguagePhrases = {
	yesterday: 'wczoraj',
	today: 'dzisiaj',
	daysAgo: 'dni temu',
	signIn: 'zalogować się',
	home: 'dom',
	signinTitle: 'Zaloguj się na swoje konto płatnicze',
	signinDesc: 'Zaloguj się przy użyciu poświadczeń portalu klienta',
	email: 'Email',
	password: 'Hasło',
	forgotPassword: 'Zapomniałem hasła?',
	emailRequired: 'Pole e-mail nie powinno być puste!',
	passwordRequired: 'Pole hasło nie powinno być puste!',
	somethingWrong: 'Wystąpił błąd!',
	emailMsg: 'Prosimy upewnić się, że podany adres e-mail jest poprawny!',
	passwordMsg:
		'Upewnij się, że Twoje hasło ma co najmniej 8 znaków, oraz zawiera co najmniej jedną wielką literę, małą literę i jeden znak specjalny.',
	becomeMerchant: 'zostać Handlowcem',
	yourAccountIsInactive: 'Twoje konto jest nieaktywne. Skontaktuj się z administratorem, aby go aktywować',
	regularUserHome: 'Strona główna zwykłego użytkownika',
	merchantHome: 'Strona główna handlowca',
	merchantsOffer: 'Oferty kupców',
	notificationsHeading: 'Powiadomienia',
	notificationsSubtitle: 'Zwiększanie możliwości dzięki aktualnym alertom! Pozostań w kontakcie, bądź na bieżąco.',
	searchInMerchantsTable: 'Szukaj w tabeli kupców',
	problemLoadingListOfMerchants: 'Wystąpił problem podczas ładowania listy sprzedawców',
	name: 'nazwa',
	rating: 'ocena',
	totalReview: 'Całkowita recenzja',
	ordersCompleted: 'Zamówienia zrealizowane',
	price: 'cena',
	available: 'dostępny',
	minLimit: 'minimalny limit',
	maxLimit: 'maksymalna granica',
	sell: 'sprzedać',
	buy: 'Ale',
	accountVerification: 'Weryfikacja konta',
	problemLoadingMerchant: 'Wystąpił problem podczas ładowania informacji o sprzedawcy',
	merchantInfo: 'informacje handlowe',
	wantToBuy: 'chcę kupić',
	wantToSell: 'chcę sprzedać',
	amountIsRequired: 'wymagana jest kwota',
	willSend: 'wyślę',
	willReceive: 'otrzymam',
	paymentMethod: 'metoda płatności',
	paymentMethodIsRequired: 'Metoda płatności jest wymagana',
	valueInRangeError: 'kwota powinna mieścić się w tym zakresie:',
	merchants: 'Kupcy',
	countryCurrency: 'Kraj/waluta',
	paymentMethods: 'Metody Płatności',
	myDetails: 'Moje szczegóły',
	changePassword: 'Zmień hasło',
	myDetailsForm: 'Formularz Moje dane',
	myProfile: 'Mój profil',
	firstName: 'Imię',
	surname: 'Nazwisko',
	phone: 'Telefon',
	address: 'Adres',
	city: 'Miasto',
	zip: 'Zamek błyskawiczny',
	state: 'Państwo',
	country: 'Kraj',
	registrationDate: 'Data rejestracji',
	newPassword: 'Nowe hasło',
	currentPassword: 'aktualne hasło',
	confirmPassword: 'Potwierdź hasło',
	updatePassword: 'Aktualizować hasło',
	changePasswordForm: 'Zmień formularz hasła',
	yourPasswordGotUpdated: 'Twoje hasło zostało zaktualizowane',
	somethingWentWrongWithUpdatingPassword: 'Podczas aktualizowania hasła coś poszło nie tak',
	pleaseMakeSurePasswordIsValid:
		'Upewnij się, że hasło zawiera co najmniej jedną wielką literę, małą literę, znak specjalny, a długość hasła powinna wynosić co najmniej 8',
	twoPasswordsDoNotMatch: 'Oba hasła muszą być takie same',
	myOffers: 'Moje oferty',
	yourSellerFormGotUpdated: 'Twój formularz sprzedawcy został zaktualizowany',
	somethingWentWrongWithUpdatingSellerForm: 'Podczas aktualizowania formularza sprzedawcy coś poszło nie tak',
	yourBuyerFormGotUpdated: 'Twój formularz kupującego został zaktualizowany',
	somethingWentWrongWithUpdatingBuyerForm: 'Podczas aktualizowania formularza kupującego coś poszło nie tak',
	sellOffer: 'Oferta sprzedaży',
	profitRatio: 'Wskaźnik zysku',
	profitRatioIsRequired: 'Wymagany jest wskaźnik zysku',
	profitRatioInRangeError: 'Wskaźnik zysku musi być pomiędzy',
	atleastSelectOneMethod: 'Wybierz co najmniej jedną metodę płatności',
	maximumAmount: 'Maksymalna ilość',
	valueMustBePositive: 'Wartość musi być dodatnia',
	mustBeGreaterThanMinValue: 'Wartość musi być większa niż wartość minimalna',
	minimumAmount: 'Stawka minimalna',
	mustBeLessThanMaxValue: 'Wartość musi być mniejsza niż wartość maksymalna',
	visibleForUsers: 'Widoczne dla użytkowników',
	no: 'NIE',
	yes: 'Tak',
	update: 'Aktualizacja',
	buyOffer: 'Kup ofertę',
	logout: 'Wyloguj',
	termsOfUse: 'Warunki korzystania',
	companies: 'Firmy',
	news: 'Aktualności',
	privacyPolicy: 'Polityka prywatności',
	merchantsOfferSubtitle:
		'Odkryj transakcje P2P Tether USDt bez opłat i wybierz swoją preferowaną metodę płatności na naszej platformie już dziś!',
	limit: 'Limit',
	countries: 'Kraje',
	min: 'Minimum',
	max: 'Maksymalny',
	all: 'Wszystko',
	merchantProfile: 'Profil sprzedawcy',
	availableLimit: 'Dostępny / Limit',
	trade: 'Handel',
	fees: 'Opłaty',
	reviews: 'Opinie',
	sellers: 'Sprzedawców',
	buyers: 'Kupujący',
	termsAndConditions: 'Zasady i Warunki',
	unitPrice: 'Cena jednostkowa',
	youSale: 'Sprzedajesz',
	youReceive: 'otrzymujesz',
	selectPaymentMethods: 'Wybierz metody płatności',
	account: 'Konta',
	note: 'Notatka',
	pleaseWriteYourNote: 'Proszę napisać swoją notatkę',
	certainPaymentMethodsMayHaveFees:
		'Niektóre metody płatności mogą wiązać się z opłatami i dziennymi limitami ustalonymi przez dostawcę usług płatniczych.',
	youPay: 'Ty płacisz',
	orders: 'Zamówienia',
	welcomeBack: 'Witamy spowrotem',
	loginToYourAccount: 'Zaloguj się na swoje konto',
	pleaseEnterYourEmail: 'Wpisz swój adres e-mail',
	enterPassword: 'Wprowadź hasło',
	notRegistered: 'Nie zarejestrowany?',
	or: 'Lub',
	createAnAccount: 'Utwórz konto',
	readOur: 'Przeczytaj nasze',
	signinWarningNote:
		'OSTRZEŻENIE: Nieautoryzowany dostęp do tego systemu jest zabroniony i będzie ścigany przez prawo. Uzyskując dostęp do tego systemu, zgadzasz się, że Twoje działania mogą być monitorowane w przypadku podejrzenia nieautoryzowanego użycia',
	allRightsReserved: 'Wszelkie prawa zastrzeżone',
	availableIsRequired: 'Dostępne jest wymagane',
	somethingWentWrongVerification: 'Coś poszło nie tak. Upewnij się, że przesłane dokumenty zostały zweryfikowane',
	myOrders: 'Moje zamówienia',
	myOrderSubtitle: 'Historia się powtarza: Bezproblemowe ponowne zamówienia, za każdym razem!',
	calculatedPrice: 'Obliczona cena',
	status: 'Status',
	dateRange: 'Zakres dat',
	sent: 'Wysłane',
	recieved: 'Odebrane',
	active: 'Aktywne',
	pending: 'Oczekujące',
	completed: 'Zakończone',
	cancel: 'Anuluj',
	inDispute: 'W sporze',
	id: 'ID',
	quantity: 'Ilość',
	amount: 'Kwota',
	dateAndTime: 'Data i godzina',
	searchOrder: 'Kolejność wyszukiwania',
	transferPaymentToSeller: 'Przelej płatność do Sprzedawcy',
	pendingSellerToReleasePayment: 'Oczekiwanie na zwolnienie płatności przez sprzedawcę',
	orderNumber: 'Numer zamówienia',
	timeCreated: 'Czas stworzony',
	activeOrder: 'Aktywne zamówienie',
	pendingOrder: 'Zamówienie oczekujące',
	completedOrder: 'Zrealizowane zamówienie',
	cancelledOrder: 'Anulowane zamówienie',
	buyFrom: 'Kupić z',
	buyUSDFrom: 'KUP USD od',
	buyIQDFrom: 'KUP IQD od',
	orderCreatedConfirmation: 'Zamówienie zostało utworzone. Proszę czekać na potwierdzenie systemu',
	orderWaitingMessage:
		'Oczekiwanie na potwierdzenie płatności od sprzedawcy w celu realizacji zamówienia. 95% zamówienia tego sprzedawcy zostało zrealizowane w ciągu 3 minut.',
	orderCompletedMessage:
		'Zamówienie zostało pomyślnie oznaczone jako zrealizowane. Jeśli masz jakiś problem z Płatnościami, skontaktuj się z pomocą techniczną, aby go rozwiązać.',
	youSuccessfullyBought: 'Pomyślnie kupiłeś',
	IQDFrom: 'IQD od',
	thisOrderHasBeenCancelled: 'to zamówienie zostało anulowane',
	thisOrderIsInDispute: 'To zamówienie jest przedmiotem sporu',
	orderAmount: 'Kwota zamówienia',
	youPaid: 'Zapłaciłeś',
	accountName: 'Nazwa konta',
	orderDetails: 'Szczegóły zamówienia',
	payWithZenCash: 'Zapłać za pomocą ZenCash',
	selectPaymentCompleted: 'Wybierz Płatność zakończona, aby powiadomić Sprzedawcę',
	paymentCompletedNotify: 'Płatność zakończona, powiadom Sprzedawcę',
	paymentReceived: 'Otrzymano płatność',
	BackToHome: 'Wrócić do domu',
	reorder: 'zmienić kolejność',
	contactSupport: 'Skontaktuj się z pomocą techniczną',
	cancelOrder: 'Anuluj zamówienie',
	completeYourPayment: 'Dokończyć płatność?',
	note1: 'Potwierdź, że pomyślnie przelałeś pieniądze sprzedającemu.',
	note2: 'Uwaga: nie kontynuuj, jeśli nie ukończyłeś płatności. Może to spowodować zawieszenie kwoty.',
	rateTheMerchant: 'Oceń sprzedawcę:',
	orderS: 'Zamówienia',
	lastSeen: 'Ostatnio widziany',
	ago: 'temu',
	yourName: 'Twoje imię',
	yourEmail: 'Twój email',
	yourPhone: 'Twój telefon',
	subject: 'Temat',
	message: 'Wiadomość',
	nameIsRequired: 'imie jest wymagane',
	emailIsRequired: 'email jest wymagany',
	phoneIsRequired: 'Telefon jest wymagany',
	subjectIsRequired: 'Temat jest wymagany',
	messageIsRequired: 'Wiadomość jest wymagana',
	sendMessage: 'Wyślij wiadomość',
	bestSellers: 'Bestsellery',
	maxOrders: 'Maksymalna liczba zamówień',
	minOrders: 'Minimalne zamówienia',
	importantNote: 'Ważna uwaga',
	confirm: 'Potwierdzać',
	confirmContactingMerchantInChat:
		'Pamiętaj, aby przed dokonaniem jakiejkolwiek płatności skontaktować się ze Sprzedawcą. {{company_name}} nie ponosi odpowiedzialności za jakiekolwiek Straty.',
	myAccounts: 'Moje Konta',
	myAccountsSubtitle:
		'Zdobądź klarowny przegląd swoich aktywów Tether USDt P2P, włączając w to saldo i szczegóły konta.',
	tradingPlatform: 'Platforma Handlowa',
	accountType: 'Typ Konta',
	accountGroup: 'Grupa Konta',
	accountNumber: 'Numer Konta',
	balance: 'Saldo',
	onHoldBalance: 'Saldo Zablokowane',
	client: 'Klient',
	emailUs: 'Napisz do nas',
	merchant: 'Sprzedawca',
	writeMessage: 'Napisz wiadomość',
	availability: 'Dostępność',
	buyersTerm: 'Warunki i postanowienia kupującego',
	sellersTerm: 'Warunki i postanowienia sprzedającego',
	bankDeposit: 'Wpłata na konto bankowe',
	accHolder: 'Oryginalny właściciel konta',
	thirdParty: 'Nie akceptujemy płatności zewnętrznych',
	wedFri: 'Środa-Piątek',
	merchantReview: 'Recenzje handlowca',
	apiNotFound: 'Nie znaleziono interfejsu API',
	unableToAuthenticateTheUser: 'Nieprawidłowa lub wygasła sesja',
	somethingWentWrong: 'Coś poszło nie tak',
	constraintViolated: 'Naruszono ograniczenie',
	recordIsLinked: 'Rekord jest powiązany',
	parametersCannotBeNull: 'Parametry nie mogą mieć wartości null',
	methodArgumentsAreInvalid: 'Argumenty metody są nieprawidłowe',
	fileUploadingError: 'Błąd przesyłania pliku',
	parameterIsNotValid: 'Parametr żądania jest nieprawidłowy',
	requiredParametersAreMissing: 'Brak wymaganych parametrów',
	pleaseEnterValidEmailAddress: 'Proszę wpisać aktualny adres e-mail',
	passwordDoesNotMatchRequirements:
		'Hasło nie spełnia wymagań: minimum 8 i maksymalnie 16 znaków, zawiera co najmniej jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny',
	invalidMerchantOfferRequest: 'Nieprawidłowe żądanie oferty sprzedawcy',
	invalidTradingAccountGroup: 'Nieprawidłowa grupa kont handlowych',
	countryIsNotAllowed: 'Kraj nie jest dozwolony w systemie P2P',
	orderNumberProvidedIsInvalid: 'Podany identyfikator zamówienia jest nieprawidłowy',
	orderIsClosed: 'Zamówienie jest zamknięte',
	actionNotApplicable: 'Działanie nie dotyczy',
	errorOccurredWhileProcessingYourRequest: 'Wystąpił błąd podczas przetwarzania Twojego żądania',
	problemOccurredWhileLoadingRecords: 'Wystąpił problem podczas ładowania rekordów',
	problemOccurredWhileLoadingRecord: 'Wystąpił problem podczas ładowania rekordu',
	problemOccurredWhileAddingRecord: 'Wystąpił problem podczas dodawania rekordu',
	problemOccurredWhileUpdatingRecord: 'Wystąpił problem podczas aktualizacji rekordu',
	problemOccurredWhileDeletingRecord: 'Wystąpił problem podczas usuwania rekordu',
	recordAlreadyExists: 'Rekord już istnieje',
	noRecordFound: 'Nie znaleziona nagrania',
	accessDeniedMissingKyc: 'Odmowa dostępu z powodu braku Kyc',
	invalidCredentials: 'Nieprawidłowe dane uwierzytelniające',
	invalidPaymentMethod: 'Nieprawidłowa metoda płatności',
	invalidOfferType: 'Nieprawidłowy typ oferty',
	orderIsInProgress: 'Zamówienie jest w trakcie realizacji',
	orderReviewIsAlreadyPresent: 'Przegląd zamówienia jest już obecny',
	orderIsClosedMoreThanWeekAgo: 'Zamówienie zostało zamknięte ponad tydzień temu',
	userIsInactive: 'Użytkownik jest nieaktywny',
	userCountryNotAllowed: 'Kraj użytkownika nie jest dozwolony',
	orderIsExpiredOrClosed: 'Zamówienie wygasło lub zostało zamknięte',
	invalidOrderAction: 'Nieprawidłowa akcja zamówienia',
	onlyOneOrderAllowedAtTime: 'Dozwolone jest tylko jedno zamówienie na raz',
	invalidTradingAccount: 'Nieprawidłowe konto handlowe',
	merchantBelongsToDifferentCountry: 'Nie można handlować! Sprzedawca należy do innego kraju',
	orderAmountIsMoreThanAvailableBalance: 'Kwota zamówienia przekracza dostępne saldo!',
	merchantOfferNotAvailable: 'Oferta sprzedawcy niedostępna!',
	amountDoesNotSatisfyAvailableLimit: 'Kwota nie przekracza dostępnego limitu!',
	iraq: 'Irak',
	lebanon: 'Liban',
	saudi: 'Arabia Saudyjska',
	egypt: 'Egipt',
	jordan: 'Jordania',
	requestWasSentSuccessfully: 'Żądanie zostało pomyślnie wysłane',
	noReviewsForThisMerchant: 'Brak recenzji tego sprzedawcy',
	problemLoadingListOfOrders: 'Wystąpił problem podczas ładowania listy zamówień',
	new: 'Nowy',
	cancelled: 'Odwołany',
	transferred: 'Przeniesiony',
	sellerConfirmed: 'Sprzedawca potwierdzony',
	cancellationRequest: 'Żądanie anulowania',
	timedOut: 'Przekroczono limit czasu',
	appealed: 'Apelacja',
	rejected: 'Odrzucony',
	closed: 'Zamknięte',
	myOffersSubtitle: 'To są oferty, które zostaną przedstawione Twoim klientom na Merchant.',
	merchantDetails: 'Szczegóły sprzedawcy',
	merchantDetailsDescription:
		'Eksploruj szczegółowe profile sprzedawców i ich opinie, oferując cenne wskazówki, aby poprawić swoje doświadczenia z handlu P2P Tether USDt.',
	onlyClientsCanStartOrders: 'Tylko klienci mogą rozpoczynać zamówienia',
	maxValueRange: 'Wartość maksymalna powinna mieścić się w przedziale od 1 do {{amount}}',
	noteMinLength: 'Notatka musi zawierać więcej niż 20 znaków',
	noteMaxLength: 'Notatka nie może przekraczać 1000 znaków',
	cantOpenOrderNows: 'Nie możesz rozpocząć nowego zamówienia, ponieważ masz zamówienie w toku',
	amountLessThanMerchantAvailable: 'Kwota powinna mieścić się w odpowiednim przedziale',
	amountLessThanYourBalanceLimit: 'Kwota powinna być mniejsza niż saldo',
	orderWith: 'Zamów z',
	receivingPaymentFromBuyer: 'Otrzymanie płatności od kupującego',
	releasePaymentToBuyer: 'Zwolnij płatność kupującemu',
	opened: 'otwierany',
	appeal: 'odwołanie',
	expired: 'wygasły',
	placed: 'umieszczony',
	unknown: 'nieznany',
	pleaseWaitUntilMerchantAcceptOrder: 'Poczekaj, aż sprzedawca zaakceptuje zamówienie',
	pleaseAcceptTheOrderBeforeItsExpired: 'Proszę przyjąć zamówienie przed jego wygaśnięciem',
	thisOrderHasBeenCanceled: 'To zamówienie zostało anulowane',
	thisOrderHasBeenExpired: 'To zamówienie wygasło',
	thisOrderHasBeenRejected: 'To zamówienie zostało odrzucone',
	thisOrderIsInAppeal: 'Zarządzenie to jest przedmiotem odwołania',
	theOrderStateIsUnknown: 'Stan zamówienia jest nieznany',
	writeReviewAboutTheMerchant: 'Napisz recenzję o sprzedawcy',
	reviewIsRequired: 'Wymagana jest recenzja',
	paymentDetails: 'Szczegóły płatności',
	paymentCompleted: 'Płatność zakończona',
	accept: 'Zaakceptować',
	reject: 'Odrzucić',
	finishedSuccessfully: 'Zakończono pomyślnie',
	chatIsDisabled: 'Czat jest wyłączony',
	areYouSureYouWantToAcceptOrder: 'Czy na pewno chcesz przyjąć to zamówienie?',
	cancelThisOrder: 'Anuluj to zamówienie',
	inzoDoesnNotProvideAutomaticRefunds:
		'{{company_name}} nie zapewnia automatycznych zwrotów pieniędzy. Nie anuluj zamówienia, jeśli zapłaciłeś, ale nie otrzymałeś zwrotu pieniędzy. Zgłoś spór, jeśli potrzebujesz pomocy.',
	waitForReceivingConfirmation: 'Proszę poczekać na potwierdzenie otrzymania pieniędzy',
	gotIt: 'Rozumiem',
	makeSureToContactMerchant:
		'Przed dokonaniem jakiejkolwiek płatności prosimy o kontakt ze Sprzedawcą. {{company_name}} nie będzie odpowiadać za jakiekolwiek straty.',
	makeSureToContactClient:
		'Przed dokonaniem jakiejkolwiek płatności prosimy o kontakt z Klientem. {{company_name}} nie będzie odpowiadać za jakiekolwiek straty.',
	importNote: 'Ważna uwaga',
	paymentCompletedNote1: 'Potwierdź, że pomyślnie przesłałeś pieniądze sprzedającemu.',
	paymentCompletedNote2: 'Uwaga: nie kontynuuj, jeśli nie dokonałeś płatności. Może to skutkować zawieszeniem konto.',
	sucessfullyCompletedPaymentToSeller: 'Pomyślnie zrealizowałem płatność na rzecz sprzedającego.',
	paymentReceivedNote1: 'Potwierdź, że pomyślnie otrzymałeś pieniądze od kupującego.',
	paymentReceivedNote2: 'Uwaga: nie kontynuuj, jeśli nie otrzymałeś płatności. Może to skutkować zawieszeniem konto.',
	conofirmYouReceivedPayment: 'Potwierdź, że otrzymałeś płatność.',
	sucessfullyCompletedPaymentToBuyer: 'Pomyślnie zrealizowałem płatność na rzecz kupującego.',
	areYouSureYouWantToReject: 'Czy na pewno chcesz odrzucić to zamówienie?',
	transferWasSentSuccessfully: 'Przelew został pomyślnie wysłany do drugiej części',
	accepted: 'Przyjęty',
	finishedSuccessfuly: 'Zakończono pomyślnie',
	typeMessageHere: 'Wpisz tutaj wiadomość',
	markAllAsRead: 'Oznacz wszystkie jako przeczytane',
	chooseMerchantFromYourCountry: 'Wybierz sprzedawcę ze swojego kraju',
	feedbackOnThisOrder: 'Opinia na temat tego zamówienia',
	visit: 'Odwiedzać',
	seeOrderDetails: 'Zobacz szczegóły zamówienia',
	pleaseWaitUntilThePaymentIsReleased: 'Proszę poczekać, aż płatność zostanie zwolniona',
	waitUntilMerchantReleasesPayment: 'Poczekaj, aż sprzedawca zwolni płatność',
	waitUntilClientTransfersPayment: 'Poczekaj, aż klient przeleje płatność',
	waitUntilMerchantConfirmsReceivingPayment: 'Poczekaj, aż sprzedawca potwierdzi otrzymanie płatności',
	waitUntilClientConfirmsReceivingPayment: 'Poczekaj, aż klient potwierdzi otrzymanie płatności',
	paymentTransfered: 'Płatność przeniesiona',
	inzoDoesNotProvideAutoRefund:
		'{{company_name}} nie zapewnia automatycznych zwrotów pieniędzy. Proszę zalogować się na konto odbiorcy, aby sprawdzić status płatności.',
	iReceivedAmountFromBuyer: 'Otrzymałem od kupującego kwotę {{amount}}.',
	iCheckedPaymentFrom: 'Sprawdziłem, czy płatność pochodzi od {{userName}}',
	iAgreeToReleasePaymentToBuyer: 'Wyrażam zgodę na przekazanie mojej płatności kupującemu.',
	transferWasSentSuccessfullyClient: 'Zakupiłeś {{amount}} z powodzeniem od {{username}}',
	transferWasSentSuccessfullyMerchant: 'Sprzedałeś {{amount}} z powodzeniem użytkownikowi {{username}}',
	reviewSubmitted: 'Opinia została pomyślnie przesłana',
	notificationNewOrderMessage: `Nowe zamówienie {{orderType}} o numerze {{orderNumber}} na kwotę {{amount}} zostało otwarte.`,
	notificationOrderNewMessage: `Nowa wiadomość w zamówieniu #{{orderNumber}} o {{createdAt}}`,
	notificationOrderExpiryWarning: `Twoje zamówienie #{{orderNumber}} jest o krok od wygaśnięcia o {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `Zamówienie #{{orderNumber}} wygasło.`,
	notificationOrderStatusUpdate: `Zmiana statusu zamówienia #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `Twoja oferta {{offerType}} została zdjęta z powodu {{reason}}`,
	notificationAccountBalanceLow: `Saldo konta Twojego handlowca jest niskie. Obecne saldo: {{amount}}`,
	notificationSellAvailableLow: `Ilość oferty sprzedaży, którą określiłeś, jest na wyczerpaniu. Zaktualizuj ilość, aby oferta pozostała ważna. Obecne dostępne saldo sprzedaży: {{sellAvailable}}`,
	seeMore: 'Zobacz wiecej',
	noNotificationsFound: `Nie znaleziono powiadomien`,
	totalOrder: 'Porządek całkowity',
	offerType: 'Rodzaj oferty',
	offerNotAvailable: 'Ta oferta nie jest dostępna w chwili obecnej.',
	errorProccessingMT5: 'Wystąpił błąd podczas przetwarzania żądania MT5.',
	amountDosentSatisfyLimit: 'Kwota nie spełnia dozwolonego limitu oferty!',
	noteErrorMessage: 'Notatka nie powinna być dłuższa niż 1000 znaków!',
	type: 'Typ',
	resonsForCancellation: 'Reason for cancellation',
	resonsForCancellationNote:
		'To avoid loss, do not cancel the order after you have made the payment. {{company_name}} is not responsible for any kind of loss',
	unableToMakePayment: 'Nie można dokonać płatności',
	dontKnowHowToPay: 'Nie wiem, jak płacić',
	discussedWithSeller: 'Omówione z sprzedawcą, aby nie kontynuować',
	poorSellerAttitude: 'Złe podejście sprzedawcy',
	cantContactSeller: 'Nie można skontaktować się ze sprzedawcą',
	suspectSellerFraud: 'Podejrzenia oszustwa ze strony sprzedawcy',
	others: 'Inne',
	userBlock: 'Zablokowano',
	userUnblock: 'Odblokowano',
	userSuspend: 'Zawieszono',
	newReview: 'Nowa opinia',
	userUnsuspend: 'Odzawiono',
	accessDenied: 'Brak dostępu',
	chatHasBeenClosed: 'Czat został zamknięty',
	emailAlreadyExist: 'e-mail już istnieje',
	phoneNumberDoesNotMatchTheRequirement: 'Numer telefonu nie odpowiada wymaganiom',
	newPasswordAndConfirmPasswordNotSame: 'Nowe hasło i potwierdź, że hasło nie jest takie samo',
	userIsBlocked: 'Użytkownik jest zablokowany',
	userIsSuspended: 'Użytkownik jest zawieszony',
	invalidOTP: 'Nieprawidłowe hasło jednorazowe',
	merchantNotFound: 'Nie znaleziono sprzedawcy',
	cancelAppeal: 'Anuluj Odwołanie',
	revert: 'COFNĄĆ',
	release: 'WYDAĆ',
	copiedSuccessfully: 'Skopiowano pomyślnie',
	userSuspended: 'Utilisateur suspendu',
	merchantNewReview: 'Nowa opinia sprzedawcy',
	withdrawal: 'Wypłata',
	deposit: 'Depozyt',
	chatnow: 'Chat Now',
	expireIn: 'Expire In',
	releasePayment: 'Release Payment To Buyer',
	paywith: 'Pay with {{method}}',
	notFound: 'Order Not Found',
	invalidType: 'Nieprawidłowe rozszerzenie pliku. Proszę przesłać plik w formacie JPG, JPEG, PNG lub PDF.',
	fileExceedLimit: 'Rozmiar pliku przekracza dozwolony limit (5 MB).',
	accountLimit: 'Limit konta',
	maxValueAvailable: 'Kwota jest większa niż dostępna',
	cannotEnableOffer: 'Nie można aktywować oferty z powodu niskiego salda',
	show: 'Pokaż',
	entries: 'Wpisy',
	sortBy: 'sortuj według',
	startDate: 'Data rozpoczęcia',
	endDate: 'Data zakończenia',
	enterYourOtp: 'Wprowadź kod weryfikacyjny swojego e-maila',
	amountmustbelessThanavailabel: 'Kwota musi być mniejsza niż dostępne saldo {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'Kwota musi być większa niż minimum',
	minAllowedValue: 'Minimalna kwota musi być większa lub równa {{amount}} {{currency}}',
	maxAllowedValue: 'Maksymalna kwota musi być większa lub równa {{amount}} {{currency}}',
	priceLow: 'Cena od najniższej',
	priceHight: 'Cena od najwyższej',
	didNotreceiveOtp: 'Vous n avez pas reçu le code ?',
	resend: 'Wyślij ponownie',
	withdrawalOffer: 'Oferta wypłaty',
	depositOffer: 'Oferta depozytu',
	NEW_ORDER: 'Nowe zamówienie',
	ORDER_NEW_MESSAGE: 'Nowa wiadomość w zamówieniu',
	ORDER_EXPIRY_WARNING: 'Ostrzeżenie o wygaśnięciu zamówienia',
	ORDER_EXPIRED: 'Zamówienie wygasło',
	ORDER_STATUS_UPDATE: 'Aktualizacja statusu zamówienia',
	OFFER_DISABLED: 'Oferta wyłączona',
	ACCOUNT_BALANCE_LOW: 'Niski stan konta',
	SELL_AVAILABLE_LOW: 'Niska dostępność sprzedaży',
	MERCHANT_NEW_REVIEW: 'Nowa recenzja handlowca',
	USER_SUSPEND: 'Zawieszenie użytkownika',
	USER_UNSUSPEND: 'Odblokowanie użytkownika',
	USER_BLOCK: 'Zablokowanie użytkownika',
	USER_UNBLOCK: 'Odblokowanie użytkownika',
	unitPriceChange: 'Zmieniła się cena jednostkowa. Proszę sprawdzić nowe ceny przed złożeniem zamówienia.',
	allowBalance: 'Proszę wpłacić {{amount}} USD, aby włączyć oferty.',
	priceChange: 'Zmiana ceny',
	YouReceive: 'Otrzymujesz',
	Youpay: 'Płacisz',
	Seeorderdetails: 'Zobacz szczegóły zamówienia',
	INSUFFICIENT_BALANCE: 'Niewystarczający stan konta',
	COOLDOWN: 'Okres ostygnięcia',
	BY_ADMIN: 'Przez administratora',
	termsAndConditionError: 'Handlowiec nie zaakceptował warunków i postanowień',
	termsAndConditionsTitle: 'Warunki i postanowienia P2P dla handlowca {{company_name}}',
	acceptTerms: 'Akceptuję warunki i postanowienia',
	aggree: 'Zgadzam się',
	RegistrationAndqualification: 'Rejestracja i kwalifikacja',
	RegistrationAndqualification1:
		'Handlowcy muszą zarejestrować się jako handlowiec P2P {{company_name}} i dostarczyć wymagane informacje do kwalifikacji.',
	RegistrationAndqualification2:
		'Handlowcy muszą dostarczyć ważne dokumenty tożsamości z dowodem zamieszkania, a odbędzie się spotkanie z handlowcem w celu uwierzytelnienia jego konta.',
	RegistrationAndqualification3:
		'Kwalifikacja podlega zatwierdzeniu przez firmę, a warunki mogą być modyfikowane w dowolnym czasie.',
	TransactionsAndfees: 'Transakcje i opłaty',
	TransactionsAndfees1:
		'Handlowcy muszą przestrzegać zatwierdzonych opłat i stawek za usługę P2P {{company_name}} Merchant.',
	TransactionsAndfees2: 'Firma ma prawo aktualizować opłaty i ceny i informować handlowców o tym.',
	CommitmentTosafety: 'Zobowiązanie do bezpieczeństwa',
	CommitmentTosafety1:
		'Handlowcy muszą podjąć ścisłe środki bezpieczeństwa w celu ochrony informacji klienta i transakcji.',
	CommitmentTosafety2: 'Należy zapewnić bezpieczne i pewne środowisko do przeprowadzania transakcji.',
	Compliancewithlawsandregulations: 'Zgodność z prawem i regulacjami',
	Compliancewithlawsandregulations1:
		'Handlowcy muszą przestrzegać wszystkich praw i regulacji dotyczących usług finansowych i P2P {{company_name}} Merchant.',
	Positiveinteractionwithcustomers: 'Pozytywna interakcja z klientami',
	Positiveinteractionwithcustomers1:
		'Handlowcy muszą pozytywnie współdziałać z klientami, świadczyć skuteczne wsparcie i szybkość w realizacji zamówień. Skargi i problemy muszą być rozwiązywane szybko i skutecznie.',
	Terminationofservice: 'Zakończenie usługi',
	Terminationofservice1:
		'Firma zastrzega sobie prawo do zakończenia usługi handlowca, jeśli nie zostaną spełnione ustalone warunki i warunki.',
	send: 'Wyślij',
	amountDoesNotSatisfyAccountGroupLimit: 'Kwota nie spełnia limitu grupy kont',
	limitReached: 'Osiągnięto limit zamówień od sprzedawcy!',
	OTPIsrequired: 'Wymagane jest wprowadzenie kodu OTP!',
	Pleaseuploadthepaymentproof: 'Proszę załadować dowód płatnośc',
	ChooseFile: 'Wybierz plik',
	selectPaymentProof: 'Wybierz dowód płatności',
	fileUploadRequired: 'Plik jest wymagany',
	download: 'Pobierz',
	live: 'Na żywo',
	submit: 'Zatwierdź',
	back: 'Powrót',
	of: 'z',
	items: 'elementy',
	agent: 'Agent',
	tradersRoom: 'Pokój handlowców',
	exchangeRateUpdate: 'Kurs wymiany został zmieniony z {{oldExchangeRate}} na {{newExchangeRate}} przez {{role}}.',
	EXCHANGE_RATE_UPDATE: 'Aktualizacja kursu wymiany',
	ORDER_REJECTION_WARNING: 'Ostrzeżenie o odrzuceniu zamówienia',
	sessionExpired: 'Sesja wygasła',
	orderRejectionWarning: 'Odrzucenie zamówienia jeszcze raz spowoduje dezaktywację twoich ofert',
	suspendClient: 'Zawiesić klienta',
	suspendMerchant: 'Zawiesić handlowca',
	unsuspendMerchant: 'Odwiesić handlowca',
	unsuspendClient: 'Odwiesić klienta',
	restricted: 'Ograniczone',
	completionRate: 'ukończenia',
	otpexpired: 'Kod OTP wygasł!',
	reachedotplimit: 'Osiągnąłeś maksymalną liczbę prób dla OTP!!',
	annoncement: 'Zaplanowane utrzymanie portalu od {{from}} do {{to}}',
	validationChatMessage: 'specjalne <, >, & są niedozwolone.',
	showLiveChat: 'Pokaż czat na żywo',
	hideLiveChat: 'Ukryj czat na żywo',
};
