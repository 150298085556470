import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { useConfigurationContext } from 'store/ConfigurationContext';

import {
	themeColor as defaultThemeColor,
	companyWebsite as defaultcompanyWebsite,
} from '../../assets/constant/fallback-config';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Grid, Tooltip } from 'antd';
import { useMainContext } from 'store/MainContext';
import { useLocation } from 'react-router-dom';

const Footer = () => {
	const [liveChatHidden, setLiveChatHidden] = useState(false);
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { xs } = Grid.useBreakpoint();
	const { t } = useTranslation();
	const { mainState } = useMainContext();
	const location = useLocation();

	useEffect(() => {
		// Tidio is loaded globally on window.tidioChatApi
		if (window.tidioChatApi) {
			if (liveChatHidden || location.pathname.includes('order-life-cycle') || location.pathname.includes('sign-in')) {
				window.tidioChatApi.hide();
			} else {
				window.tidioChatApi.show();
			}
		}
	}, [liveChatHidden, location]);

	const { configurationState } = useConfigurationContext();
	// TODO:: MAKE IT LATER
	// const companyWebsite =
	// 	configurationState.find((item) => item.configKey === 'company_website')?.value ?? defaultcompanyWebsite;
	// const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value ?? defaultThemeColor;

	return (
		<div className='footer'>
			<div className='footer-tail bg-lightBlue py-4'>
				<div className='max-w-[1440px] mx-auto flex flex-col sm:flex-row items-center justify-between'>
					<span className='text-white text-xs lg:mb-0'>
						© {currentYear}{' '}
						<a className='text-white text-xs no-underline' href={`https://webbats.com`} target='_blank'>
							webbats
						</a>{' '}
						{t<string>('allRightsReserved')}
					</span>
				</div>
			</div>
			{mainState.isLoggedIn && !window.location.pathname.includes('order-life-cycle') && (
				<div
					className='fixed bottom-[60px] z-[9999]'
					style={{ right: liveChatHidden ? '1.5rem' : !xs ? '16.5rem' : '5rem' }}
				>
					{liveChatHidden ? (
						<Tooltip placement='left' title={t<string>('showLiveChat')}>
							<div className='w-8 h-8 rounded-full flex justify-center bg-lightBlue'>
								<LeftOutlined className='text-[20px] text-white' size={20} onClick={() => setLiveChatHidden(false)} />
							</div>
						</Tooltip>
					) : (
						<Tooltip placement='left' title={t<string>('hideLiveChat')}>
							<div className='w-8 h-8 rounded-full flex justify-center bg-lightBlue'>
								<RightOutlined className='text-[20px] text-white' onClick={() => setLiveChatHidden(true)} />
							</div>
						</Tooltip>
					)}
				</div>
			)}
		</div>
	);
};

export default Footer;
